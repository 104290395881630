<template>
  <div class="home-modal">
    <div class="home-modal__container">
      <font-awesome-icon
        class="home-modal__close-modal"
        icon="times"
        @click="closeModal"
      ></font-awesome-icon>
      <ModalNews v-if="id == 'Noticias'"/>
      <ModalRecognize v-else-if="id == 'Reconoce'"/>
      <ModalGallery v-else-if="id == 'Galería'"/>
      <ModalBirthday v-else-if="id == 'Cumpleaños'"/>
      <ModalBenefit v-else-if="id == 'Beneficios'"/>
      <ModalOrganization v-else-if="id == 'Organigrama'"/>
      <ModalSeguridad v-else-if="id == 'Seguridad'"/>
      <ModalBiblioteca v-else-if="id == 'Biblioteca'"/>
      <ModalMovimientos v-else-if="id == 'Movimientos'"/>
      <ModalIngresos v-else-if="id == 'Ingresos'"/>
      <ModalFolovid v-else-if="id == 'Folovid'"/>
      <ModalEncuestas v-else-if="id == 'Encuesta'"/>
      <ModalTransport v-else-if="id == 'Transporte'"/>
      <ModalHoteleria v-else-if="id == 'Hotelería'"/>
      <ModalVideo v-else-if="id == 'Videos'"/>

    </div>
  </div>
</template>

<script>
import ModalNews from "./ModalNews.vue";
import ModalRecognize from "./ModalRecognize.vue";
import ModalOrganization from "./ModalOrganization.vue";
import ModalGallery from "./ModalGallery.vue";
import ModalBirthday from "./ModalBirthday.vue";
import ModalBenefit from "./ModalBenefit.vue";
import ModalSeguridad from "./ModalSeguridad.vue";
import ModalBiblioteca from "./ModalBiblioteca.vue";
import ModalMovimientos from "./ModalMovimientos.vue";
import ModalFolovid from "./ModalFolovid.vue";
import ModalIngresos from "./ModalIngresos.vue";
import ModalEncuestas from "./ModalEncuestas.vue";
import ModalTransport from "./ModalTransport.vue";
import ModalHoteleria from "./ModalHoteleria.vue";
import ModalVideo from "./ModalVideo.vue";

export default {
  props: {
    id: {
      type: String,
    },

  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
  components: {
    ModalNews,
    ModalRecognize,
    ModalBirthday,
    ModalBenefit,
    ModalGallery,
    ModalOrganization,
    ModalSeguridad,
    ModalBiblioteca,
    ModalMovimientos,
    ModalIngresos,
    ModalFolovid,
    ModalEncuestas,
    ModalTransport,
    ModalHoteleria,
    ModalVideo,
  },
  mounted(){
    console.log('ID PROP ',this.id);
  }
};
</script>
