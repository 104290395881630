<template>
  <div class="modal-news my-0 m-xxl-3">
    <h2 class="modal-news__title my-0">Galería</h2>
    <div class="input-wrapper">
      <div class="modal-news__top-filter">
        <div class="modal-news__top-left" style="color: #757679;">
          <v-select 
            class="modal-news__v-select"
            label="nombre"
            :options="filters" 
            v-model="filter" 
            @input="filterStatus(filter)"
            :clearable="false"
          ></v-select>

        </div>
        <div class="filter-container" v-show="id_filtro == 3">
         <div class="d-flex">
           <div
            class="encurso-container__calendar-container"
            v-click-outside="handleCalendar"
          >
            <div class="calendar modal-date__calendar-container" :class="calendar_side" v-if="show_calendar">
              <v-calendar
                color="blue"
                :min-date="min_date"
                :max-date="max_date"
                :attributes="attributes"
                @dayclick="onDayClick"
              />
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div
              class="encurso-container__start"
              @click="openCalendar('start-date')"
            >
              <span style="color: #757679; font-family: 'Product Sans Regular';">{{ dateFormatymd(init_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
              <div class="modal-date__icon-close">
                <font-awesome-icon 
                v-show="date_start != ''"
                icon="times" 
                @click.prevent.stop="deleteDate('start-date')"
                ></font-awesome-icon>
              </div>
            </div>
            <div
              class="encurso-container__end unactive"
              v-if="init_date == 'Inicio'"
            >
              <span style="color: #757679; font-family: 'Product Sans Regular';">{{ dateFormatymd(end_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div
              class="encurso-container__end"
              v-if="init_date != 'Inicio'"
              @click="openCalendar('end-date')"
            >
              <span>{{ dateFormatymd(end_date) }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
              <div class="modal-date__icon-close">
              <font-awesome-icon 
              v-show="date_end != ''"
              icon="times" 
              @click="deleteDate('end-date')"
              ></font-awesome-icon>
            </div>
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>
    <div class="modal-news__wrapper">
      <div class="">
        <p class="modal-news__subtitle my-2">Interacciones</p>
       <div class="modal-news__flex-container">
         <div
          class="interacciones-container"
          v-for="(d, i) in data"
          :key="i"
        >
          <div class="ms-4">
            <p class="my-0 home-modal__interaction-text">
              {{ d.total }}
            </p>
            <p class="my-0 home-modal__interactions-subtext">
              {{ d.title }}
            </p>
          </div>
          <img class="modal-news__modal-svg me-4" :src="d.image" alt="" />
        </div>
       </div>
      </div>
      <div class="carrousel-modal">
        <p class="modal-news__subtitle ms-0 my-2">Top 5 albumes más vistos</p>
        <div class="modal-news__card-noticias">
          <div class="carrousel-modal__card">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div
                class="carousel-indicators"
              >
                <button  v-show="topGallery.length > 0"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button v-show="topGallery.length > 1"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button v-show="topGallery.length > 2"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                 <button v-show="topGallery.length > 3"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                 <button v-show="topGallery.length > 4"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
              </div>
              <div class="carousel-inner">
                <div 
                  class="carousel-item " 
                  v-for="(photo,i) in topGallery" 
                  :key="i"
                  :class="{ active: i==0 }"
                >
                  <div class="my-2 carrousel-modal__card-carrousel">
                    <div class="carrousel-modal__fecha">
                      <div class="carrousel-modal__dia">
                        {{ getDay(photo.fecha_galeria) }}
                      </div>
                      <div class="carrousel-modal__mes">{{getMonth(photo.fecha_galeria)}}</div>
                    </div>
                    <img
                      :src="photo.imagenes[0].url_imagen_original"
                      class="card-img-top"
                      alt=""
                    />
                    <div>
                      <p
                        class="carrousel-modal__card-title card-text mb-0 px-3"
                      >
                        {{photo.titulo_galeria}}
                      </p>
                      <div class="container">
                        <div class="carrousel-modal__indicators-container me-4">
                          <span
                            class="
                              d-flex
                              align-content-center align-items-center
                            "
                          >
                            <img
                              class="me-1"
                              src="@/assets/img/home-admin/heart.svg"
                              height="20"
                              alt=""
                            />{{ getData(photo.total_likes_admin) }}</span
                          >
                          <span class="d-flex align-content-center ms-3">
                            <img
                              class="me-1"
                              src="@/assets/img/home-admin/8.svg"
                              alt=""
                            />{{ getData(photo.imagenes) }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span aria-hidden="true"
                  ><img src="@/assets/img/home-admin/leftarrow.svg" alt=""
                /></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span aria-hidden="true"
                  ><img src="@/assets/img/home-admin/rigtharrow.svg" alt=""
                /></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {

  components: { Spinner },

  data() {
    return {
      show_spinner: false,
      data: [
        {
          image: require("@/assets/img/home-admin/3.svg"),
          total: "...",
          title: "Visitas",
        },
        {
          image: require("@/assets/img/home-admin/2.svg"),
          total: "...",
          title: "Me gusta",
        },
        {
          image: require("@/assets/img/home-admin/5.svg"),
          total: "...",
          title: "Descargas",
        },
      ],
      // Filtro Estado
      filter: "Mensual",
      id_filtro: '1',

      // filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
    };    
  },

  computed: {
    ...mapState("adminHomeModule", ["statisticsModule", "topGallery", "filters"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },

  async created(){
    this.show_spinner = true;
    let data = { 
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
      idUsuario: this.$ls.get("user").id_usuario,
    }
    await this.getTopGallery(data);
    await this.searchMonthYear();
    this.show_spinner = false;
  },

  methods: {
    ...mapActions("adminHomeModule", ["getStatisticsModule", "getTopGallery"]),

    // filtro por estado
    async filterStatus(status) {
      this.id_filtro = status.id;
      if( this.id_filtro == '1' || this.id_filtro == '2') await this.searchMonthYear();
    },

    // Busqueda mensual y anual
    async searchMonthYear(){
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: "7",
        idFiltro: this.id_filtro,
        fechaInicio: this.getToday(),
        fechaFin: "",
      };
      await this.getStatisticsModule(data);
      this.fillNews();
      this.show_spinner = false;
    },

    // Obtiene mes actual y año actual (dia fijo)
    getToday(){
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let day = '20';
      month = month.toString();
      if(month.length == 1) month = '0'+month;
      return year+'-'+month+'-'+day;
    },

    // Busqueda por periodo
    async searchPerid(){
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: "7",
        idFiltro: this.id_filtro,
        fechaInicio: this.date_start,
        fechaFin: this.date_end,
      };
      await this.getStatisticsModule(data);
      this.fillNews();
      this.show_spinner = false;
    },

    // LLena modal news con datos
    fillNews(){
      this.statisticsModule.estadisticas.forEach( e => {
        if(e.nombre == 'Visitas') this.data[0].total = e.total;
        if(e.nombre == 'Likes') this.data[1].total = e.total;
        if(e.nombre == 'Descargas') this.data[2].total = e.total;
      })
    },

    // Fecha noticia
    getDay(day){
      day = new Date(day+'T00:00:00');
      return  day.getDate();
    },

    // Mes noticia
    getMonth(day){
      day = new Date(day);
      let months = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
      return months[day.getMonth()];
    },

    // comentarios y likes
    getData(data){
      if(data == undefined) return 0;
      if(data != undefined) return data.length;
    },

    // filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },

    // eliminar una o ambas fechas
    async deleteDate(side){
      if(side == "end-date"){
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
      } else {
        this.calendar_side = "",
        this.min_date = "";
        this.max_date = "";
        this.init_date = "Inicio";
        this.date_start = "";
        this.start_calendar = false,
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
      }
    },

    // abre calendario según posición (inicio o fin)
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.date_end;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },

    // busca según las fechas asignadas
    async onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
        this.searchPerid();
      }
      this.show_calendar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-noticias-intranet {
  &__texto {
    font-family: "Product Sans Regular";
    font-size: 18px;
    color: #6b6e7f;
  }
  &__fecha {
    opacity: 0.8;
    border-radius: 50%;
    color: white;
    position: absolute;
    width: 3.25em;
    height: 3.25em;
    line-height: 1.25em;
    margin-top: 16px;
    margin-left: 215px;
  }
  &__dia {
    font-size: 20px;
    letter-spacing: 0;
    position: relative;
  }
  &__mes {
    font-size: 10px;
  }
}
.boton-card-noticias {
  font-size: 16px;
}
</style>
