<template>
  <div class="modal-news my-0 m-xxl-3">
    <h2 class="modal-news__title my-0">Teamworkup TV</h2>
    <div class="input-wrapper">
      <div class="modal-news__top-filter">
        <div class="modal-news__top-left">
          <v-select 
          class="modal-news__v-select"
            label="nombre"
            :options="filters" 
            v-model="filter" 
            @input="filterStatus(filter)"
            :clearable="false"
          ></v-select>

        </div>
        <div class="modal-news__filter-container" v-show="id_filtro == 3">
          <div
            class="encurso-container__calendar-container"
            v-click-outside="handleCalendar"
          >
            <div class="calendar modal-date__calendar-container" :class="calendar_side" v-if="show_calendar">
              <v-calendar
                color="blue"
                :min-date="min_date"
                :max-date="max_date"
                :attributes="attributes"
                @dayclick="onDayClick"
              />
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div
              class="encurso-container__start"
              @click="openCalendar('start-date')"
            >
              <span>{{ dateFormatymd(init_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
              <div class="modal-date__icon-close">
              <font-awesome-icon 
              v-show="date_start != ''"
              icon="times" 
              @click.prevent.stop="deleteDate('start-date')"
            ></font-awesome-icon>
            </div>
            </div>
            <div
              class="encurso-container__end unactive"
              v-if="init_date == 'Inicio'"
            >
              <span>{{ dateFormatymd(end_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div
              class="encurso-container__end"
              v-if="init_date != 'Inicio'"
              @click="openCalendar('end-date')"
            >
              <span>{{ dateFormatymd(end_date) }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
              <div class="modal-date__icon-close">
                <font-awesome-icon 
                v-show="date_end != ''"
                icon="times" 
                @click.prevent.stop="deleteDate('end-date')"
                ></font-awesome-icon>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="">
        <p class="modal-news__subtitle mt-3 mb-0">Interacciones</p>
        <div class="modal-recognize__interactions">
          <div class="interacciones-container my-3">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ data[0].total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ data[0].title }}
              </p>
            </div>
            <img
              class="me-4 modal-news__modal-svg"
              :src="data[0].image"
              alt=""
            />
          </div>
          <div class="interacciones-container my-3">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ data[1].total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ data[1].title }}
              </p>
            </div>
            <img
              class="me-4 modal-news__modal-svg"
              :src="data[1].image"
              alt=""
            />
          </div>
          <div class="interacciones-container my-3">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ data[2].total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ data[2].title }}
              </p>
            </div>
            <img
              class="me-4 modal-news__modal-svg"
              :src="data[2].image"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {

  components: { Spinner },

  data() {
    return {
      show_spinner: false,
      data: [
        {
          image: require("@/assets/img/home-admin/3.svg"),
          total: "...",
          title: "Visitas",
        },
        {
          image: require("@/assets/img/home-admin/2.svg"),
          total: "...",
          title: "Me gusta",
        },
        {
          image: require("@/assets/img/home-admin/1.svg"),
          total: "...",
          title: "Comentarios",
        },
      ],
      // Filtro Estado
      filter: "Mensual",
      id_filtro: '1',

      // filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
    };
  },

  computed: {
    ...mapState("adminHomeModule", ["statisticsModule", "filters"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },

  async created(){
    this.show_spinner = true;
    await this.searchMonthYear();
    this.show_spinner = false;
  },

  methods: {
    ...mapActions("adminHomeModule", ["getStatisticsModule"]),

    // filtro por estado
    async filterStatus(status) {
      this.id_filtro = status.id;
      if( this.id_filtro == '1' || this.id_filtro == '2') await this.searchMonthYear();
    },

    // Busqueda mensual y anual
    async searchMonthYear(){
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: "6",
        idFiltro: this.id_filtro,
        fechaInicio: this.getToday(),
        fechaFin: "",
      };
      await this.getStatisticsModule(data);
      this.fillNews();
      this.show_spinner = false;
    },

    // Obtiene mes actual y año actual (dia fijo)
    getToday(){
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let day = '20';
      month = month.toString();
      if(month.length == 1) month = '0'+month;
      return year+'-'+month+'-'+day;
    },

    // Busqueda por periodo
    async searchPerid(){
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: "6",
        idFiltro: this.id_filtro,
        fechaInicio: this.date_start,
        fechaFin: this.date_end,
      };
      await this.getStatisticsModule(data);
      this.fillNews();
      this.show_spinner = false;
    },

    // LLena modal news con datos
    fillNews(){
      this.statisticsModule.estadisticas.forEach( e => {
        if(e.nombre == 'Visitas') this.data[0].total = e.total;
        if(e.nombre == 'Likes') this.data[1].total = e.total;
        if(e.nombre == 'Comentarios') this.data[2].total = e.total;
      })
    },

    // filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },

    // eliminar una o ambas fechas
    async deleteDate(side){
      if(side == "end-date"){
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
      } else {
        this.calendar_side = "",
        this.min_date = "";
        this.max_date = "";
        this.init_date = "Inicio";
        this.date_start = "";
        this.start_calendar = false,
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
      }
    },

    // abre calendario según posición (inicio o fin)
    openCalendar(side){
      this.show_calendar = true;
      this.calendar_side = side;
      if(this.calendar_side == "start-date") {
        this.min_date = "";
        if(this.end_calendar == true){
          this.max_date = this.date_end;
        }
      }
      if(this.calendar_side == "end-date") {
        this.max_date="";
        if(this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },

    // busca según las fechas asignadas
    async onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
        this.searchPerid();
      }
      this.show_calendar = false;
    },
  },
};
</script>
