<template>
  <div id="home">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
          <i class="fa-solid fa-home color-secondary"></i>
          ¡Conoce el comportamiento de tus usuarios! <strong>Filtra</strong>,
          <strong>revisa</strong> los indicadores claves y
          <strong>descarga</strong> reportes ingresando el periodo de tu interés.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3">
        <div class="row">
          <div class="col">
            <v-select
              class="selvue-custom"
              :clearable="false"
              disabled
              :options="year_user"
              :v-model="year_user"
              label="year"
              :placeholder="year_now">
              <span slot="no-options"></span>
            </v-select>
          </div>
          <div class="col">
            <v-select
              class="selvue-custom"
              v-model="searchMonth"
              :options="getActiveMonths()"
              @input="setSearchUsability(searchMonth)"
              :clearable="false"
              :placeholder="actual_month">
              <span slot="no-options"></span>
            </v-select>           
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-5 col-xxl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon icon="file-excel" class="color-secondary pe-2"/>
            Reporte
          </div>
          <div class="col-10 col-lg-7 col-xl-6 col-xxl-5">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormat(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormat(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 col-lg-1 d-flex">
            <button 
            :disabled="checkDataExcel() != 'active'" 
            class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill" 
            icon="download"
            v-on:click="getDataExcel()">
              <font-awesome-icon icon="download"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6 mb-5 mb-lg-0">

            <div class="row">
              <div class="col-12">
                <div class="card card-custom border-round-10 card-shadow mb-3">
                  <div class="card-body">
                    <h5 class="card-title">¡Hola! <strong>{{ usuario.primer_nombre }}</strong></h5>
                    <p class="card-text"> {{ intro_text }} </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row row-cols-2 g-2 row-cols-xxl-4">
                  <div class="col">
                    <div class="card card-shadow border-round-10 bg-blue-light-2">
                      <div class="card-body text-blue text-center">
                        <strong class="h1-custom font-title-bold mb-0">2000</strong>
                        <p class="card-text">Plan contratado</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-shadow border-round-10 bg-blue-light">
                      <div class="card-body text-blue text-center">
                        <strong class="h1-custom font-title-bold mb-0">{{ dash.total_usuarios }}</strong>
                        <p class="card-text">
                          <font-awesome-icon icon="circle-user"/> 
                          Registrados
                        </p>
                      </div>
                    </div>                  
                  </div>
                  <div class="col">
                    <div class="card card-shadow border-round-10 bg-blue">
                      <div class="card-body text-white text-center">
                        <strong class="h1-custom font-title-bold mb-0">{{ getDataFilterPerMonth != undefined ? getDataFilterPerMonth.total : '' }}</strong>
                        <p class="card-text">
                          <font-awesome-icon icon="circle-user"/> 
                          Activos
                        </p>
                      </div>
                    </div>                 
                  </div>
                  <div class="col">
                    <div class="card card-shadow border-round-10 bg-green">
                      <div class="card-body text-white text-center">
                        <strong class="h1-custom font-title-bold mb-0">{{  getDataFilterPerMonth != undefined ? getDataFilterPerMonth.percentage+'%' : '' }}</strong>
                        <p class="card-text">Usabilidad</p>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">        
                <GraphCardHome :usability="usability"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card card-custom card-shadow border-round-10">
              <div class="card-body">
                <h5 class="card-title"><strong>Ranking de módulos</strong></h5>
                <div class="list-group">
                  <div                     
                    :key="index"
                    v-for="(modulo, index) in modules">
                    <a 
                      href="javascript:" 
                      @click="showModal(modulo.nombre_modulo)"
                      v-if="checkModuleName(modulo.nombre_modulo) && (modulo.imagen_url != undefined && modulo.imagen_url != '')"
                      class="list-group-item list-group-item-action border-0 border-bottom">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                              <img :src="modulo.imagen_url" width="60">
                            </div>
                            <div class="flex-grow-1 ms-3">
                              <h6 class="card-subtitle">{{ modulo.nombre_modulo }}</h6>
                              <p class="card-text">{{ 'visitas '+ modulo.visitas }}</p>
                            </div>
                          </div>
                          <a 
                            href="javascript:" 
                            class="btn btn-light"
                          >
                            <font-awesome-icon icon="search"/>
                            Ver más
                          </a>
                        </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeModal
        v-show="modal_home == true"
        :id="module_name"
        @close-modal="closeDetail"
      />
      <Spinner v-if="show_spinner" />
    </div>
  </div>
</template>

<script>
import HomeModal from "./HomeModal.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Spinner from "../Spinner.vue";
import GraphCardHome from "./GraphCardHome.vue";
import "export-json-excel";
import { utils, writeFile } from 'xlsx';
export default {
  components: { HomeModal, Spinner, GraphCardHome },
  data() {
    return {
      searchMonth: "",
      img_download: require("@/assets/img/Descarga_white.svg"),
      options: {},
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      show_spinner: false,
      id_modulo: 0,
      module_name:'',
      activate_download: false,
      // Filtro Estado
      cat_selected: "0",
      open_select: false,
      status_list: ["Mensual", "Trimestral", "Semestral", "Anual", "Todas"],

      // filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",

      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: new Date(),
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
      fecha_fin: "",
      fecha_inicio: "",
      fields_excel: [],
      data_excel:[],
      works_sheet: "XXXXX",
      dash: {},
      modulos: [],
      modal_home: false,
      actual_month: "",
      usuario: "",
      months_filter: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      year_now: "0",
      month_filter: [],
      user_privilege: this.$ls.get("user").privilegio[0].id_tipo,

      //FF
      intro_text: "Aquí encontrarás información clave para definir acciones que potencien la usabilidad. Te recomendamos analizar métricas cada mes, actualizar contenidos semanalmente y siempre enviar notificaciones push ante nuevas actualizaciones."
    };
  },
  mounted() {
    this.getActualMonth();
    this.show_spinner = false;
  },
  computed: {
    ...mapState("adminHomeModule", [
      "dashboardEmpresa",
      "generalStatistics",
      "filters",
      "usability",
      "searchUsability",
      "dataExcel",
      "year_user",
      "dataExcelTotal"
    ]),
    ...mapState("sidebar", [
      "modules",
    ]),
    ...mapGetters("adminHomeModule", ["getDataFilterPerMonth"]),

    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  
  async created() {
    this.getYear();
    let data_modules ={
        id_empresa:this.id_empresa,
        id_privilegio: this.user_privilege,
      }
    await this.getModules(data_modules);
    
    this.show_spinner = true;
    this.getActualMonth();
    let params = {
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
      idUsuario: this.$ls.get("user").id_usuario,
    };
    let paramFilter = {
      id_filtro: "1",
      fecha_fin: this.fecha_fin,
      fecha_inicio: this.fecha_inicio,
      idEmpresa: this.id_empresa,
    };
    await this.getDashboardEmpresa(params);
    await this.getGeneralStatistics(params);
    
    await this.getGraficoUsuariosSesion(paramFilter);

    let data = JSON.parse(localStorage.getItem("vuejs__user"));
    if (data != null) {
      this.usuario = data.value;
    }
    this.dash = this.dashboardEmpresa;
    await this.getFiltersStatistics(), (this.show_spinner = false);
    this.addStatistics()
  },

  methods: {
    ...mapActions("adminHomeModule", [
      "getDashboardEmpresa",
      "getFiltersStatistics",
      "getGeneralStatistics",
      "getGraficoUsuariosSesion",
      "setSearchUsability",
      "getDataForExcel",
      "getYearDashboard",
      "getDataForExcelTotal"
    ]),
    ...mapActions("sidebar", [
      "getModules",
    ]),
    checkModuleName(name) {
      //let show_mods = ['Noticias', 'Reconoce','Galería','Cumpleaños','Beneficios','Cultura','Videos'];
      if (name == 'Noticias' || name == "Reconoce" || name == "Beneficios" || name == "Galería" || name == "Videos" || name == "Organigrama") {
        return true;
      } else {
        return false;
      }
    },
    // se debe agregar el control de que la variable se haga nula
    async getYear(){
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa
      };
      await this.getYearDashboard(data_).then(res=>{
        if(res!=null){
          this.year_now = this.year_user[0].year;
        }
        else{
          this.year_now = "2023";
        }
      });
      
    },
    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.date_start = '';
      this.date_end = '';
      this.inicio = '';
      this.fin = '';
      this.show_calendar = false;
    },
    checkDataExcel(){
      if(this.date_start != "" && this.date_end != ""){
        return 'active';
      }else{
        return '';
      }
    },
    dateFormat(date) {
      if (date != 'Inicio' && date != 'Término') {
        let new_date = new Date(date + 'T00:00:00');
        let month = (new_date.getMonth() + 1) < 10 ? '0'+(new_date.getMonth() + 1):(new_date.getMonth() + 1);
        let year = new_date.getFullYear();
        let day = new_date.getDate() < 10 ? '0'+ new_date.getDate(): new_date.getDate();
        return day + '-' + month + '-' + year;
      } else {
        return date;
      }
    },
    async getDataExcel() {
      //let fields = [];
      let data_user_activos = [];
      let data_modulos_resumen = [];
      let data_noticias_vista = [];
      let data_noticias_likes = [];
      let data_galeria_vista = [];
      let data_galeria_likes = [];
      let data_video_vista = [];
      let data_video_likes = [];
      const workbook = utils.book_new();
      let data_ = {
        id_empresa: this.id_empresa,
        id_filtro: '3',
        fecha_inicio: this.date_start,
        fecha_termino: this.date_end
      };
      //let name_actual_date = ' '+new Date();
      // console.log('getting data', data_);
      this.show_spinner = true;
      await this.getDataForExcelTotal(data_);
      console.log("Data total a exportar: ", this.dataExcelTotal);
      try{
        // Generación de usuarios activos
        if(this.dataExcelTotal.login!=null){
              this.dataExcelTotal.login.forEach(response => {
              let rut_ = response.rut_usuario + '-' +response.dv_usuario;
              let excel_data = {
                Rut:rut_,
                Nombre: response.primer_nombre,
                Apellido: response.apellido_paterno,
                Cargo: response.cargo[0].nombre_cargo,
                Gerencia: response.gerencia[0].nombre_gerencia,
                Sucursal: response.sucursal[0].nombre_sucursal,
              }
              data_user_activos.push(excel_data)
          });
          const worksheet = utils.json_to_sheet(data_user_activos);
          utils.book_append_sheet(workbook, worksheet, "Usuarios activos");
        }
        // Generación de datos de modulo
        this.dataExcelTotal.modulos.forEach(response =>{
          let excel_modulos = {
            Modulo: response.nombre_modulo,
            Visitas: response.total_vistas,
            Likes: response.aplica_likes?response.total_likes : "N/A",
            Comentarios: response.aplica_comentarios? response.total_comentarios: "N/A"
          }
          data_modulos_resumen.push(excel_modulos);
        });
        const worksheet1 = utils.json_to_sheet(data_modulos_resumen);
        utils.book_new();
        utils.book_append_sheet(workbook, worksheet1, "Resumen visitas módulos");
        
        // Generación de noticias (vistas)
        if(this.dataExcelTotal.noticias.visto_noticia!=null){
            this.dataExcelTotal.noticias.visto_noticia.forEach(response =>{
              let excel_noticias_v = {
              Noticia: response.titulo_noticia,
              Visitas: response.visto_noticia,
            }
            data_noticias_vista.push(excel_noticias_v);
          });
          const worksheet2 = utils.json_to_sheet(data_noticias_vista);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet2, "Noticias más vistas");
        }
        // Generación de noticias total likes
        if(this.dataExcelTotal.noticias.likes_noticia!=null){
            this.dataExcelTotal.noticias.likes_noticia.forEach(response =>{
            let excel_noticias_l = {
              Noticia: response.titulo_noticia,
              Total_Likes: response.likes_noticia,
            }
            data_noticias_likes.push(excel_noticias_l);
          });
          const worksheet3 = utils.json_to_sheet(data_noticias_likes);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet3, "Noticias con más likes");
        }
        // Generación de galerias total (vistas) ranking
        if(this.dataExcelTotal.galerias.visto_galeria!=null){
            this.dataExcelTotal.galerias.visto_galeria.forEach(response =>{
            let excel_geleria_ran_visitas = {
              Galeria: response.titulo_galeria,
              Visitas: response.visto_galeria,
            }
            data_galeria_vista.push(excel_geleria_ran_visitas);
          });
          const worksheet4 = utils.json_to_sheet(data_galeria_vista);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet4, "Galerias más vistas");
        }
        // Generación de galerias total (like) ranking
        if(this.dataExcelTotal.galerias.likes_galeria!=null){
            this.dataExcelTotal.galerias.likes_galeria.forEach(response =>{
              let excel_geleria_ran_visitas = {
                Galeria: response.titulo_galeria,
                Total_Likes: response.likes_galeria,
              }
              data_galeria_likes.push(excel_geleria_ran_visitas);
            });
            const worksheet5 = utils.json_to_sheet(data_galeria_likes);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet5, "Galerias con más likes");
        }

        // Generación de videos Total Vistos (ranking)
        if(this.dataExcelTotal.videos.visto_video!=null){
              this.dataExcelTotal.videos.visto_video.forEach(response =>{
              let excel_geleria_ran_visitas = {
                Videos: response.titulo_video,
                Visitas: response.visto_video,
              }
              data_video_vista.push(excel_geleria_ran_visitas);
            });
            const worksheet6 = utils.json_to_sheet(data_video_vista);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet6, "Videos más vistos");
        }

         // Generación de videos Total likes (ranking)
        if(this.dataExcelTotal.videos!=null || this.dataExcelTotal.videos.likes_video!=null){
            this.dataExcelTotal.videos.likes_video.forEach(response =>{
              let excel_geleria_ran_like = {
                Videos: response.titulo_video,
                Total_Likes: response.likes_video,
              }
              data_video_likes.push(excel_geleria_ran_like);
            });
            const worksheet7 = utils.json_to_sheet(data_video_likes);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet7, "Videos con más likes");
        }

      
      }
      catch(err){
        console.log("Error Generado: "+err);
        this.show_spinner = false;
      }
      this.show_spinner = false;
      let nombre = "Reporte LK Fecha inicio "+this.dateFormat(this.date_start)+" Fecha termino "+this.dateFormat(this.date_end)+".xlsx";
      try{
        
        writeFile(workbook, nombre);
        this.$toast.open({
          message: "El proceso de descarga ha finalizado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      }
      catch(err){
        console.log("Falla el generador de excel: ", err);
        this.$toast.open({
            message: "El proceso de descarga ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
        });
      }
    },
    addStatistics() {
      this.modules.forEach(mod => {
        let found_mod = this.generalStatistics.find(stat => stat.modulo.nombre_modulo == mod.nombre_modulo);
        if (found_mod != undefined) {
          mod.visitas = found_mod.vistos;
        }
      });
    },
    getActiveMonths() {
      let active_months = [];
      let date_ = new Date();
      let actual_month_ = date_.getMonth() + 1;
      for (let i = 0; i < actual_month_; i++) {
        active_months.push(this.months_filter[i]);
      }
      // console.log('months',active_months);
      return active_months;
    },
    getActualMonth() {
      let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      let month = new Date();
      this.actual_month = months[month.getMonth()];
      //console.log("Revisando mes: ", month.getMonth());
      let actual_date = `${new String(month.getFullYear())}-${
      new String(month.getMonth() + 1) < 10 ? '0'+new String(month.getMonth() + 1):new String(month.getMonth() + 1)
      }-${new String(month.getDate())}`;
      this.fecha_fin = actual_date;
      this.fecha_inicio = actual_date;
    },
    // filtro estado
    toggleSelect() {
      if (this.open_select) this.open_select = false;
      else this.open_select = true;
    },
    // filtro por estado
    filterStatus(status) {
      this.open_select = false;
      if (status == "Mensual") this.cat_selected = "1";
      if (status == "Trimestral") this.cat_selected = "2";
      if (status == "Semestral") this.cat_selected = "3";
      if (status == "Anual") this.cat_selected = "4";
      if (status == "Todas") this.cat_selected = "0";
    },
    // filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },
    // eliminar una o ambas fechas
    deleteDate() {
      if (this.calendar_side == "end-date") {
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false;
      } else {
        (this.calendar_side = ""), (this.min_date = "");
        this.init_date = "Inicio";
        this.date_start = "";
        (this.start_calendar = false), (this.end_calendar = false);
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false;
      }
    },
    // abre calendario según posición (inicio o fin)
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
      }
      if (this.calendar_side == "end-date") {
        if (this.start_calendar == true) this.min_date = this.date_start;
      }
    },
    // busca según las fechas asignadas
    async onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
      }
      this.show_calendar = false;
      if(this.checkDataExcel() == 'active'){
        //this.getDataExcel();
      }
    },

    closeDetail() {
      this.modal_home = false;
    },
    showModal(id_) {
      this.module_name = id_;
      this.modal_home = true;
    },
  },
};
</script>
