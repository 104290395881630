<template>
  <div class="modal-news m-4">
    <h2 class="modal-news__title2 my-0">Hotelería</h2>
    <div class="input-wrapper">
      <div class="modal-news__top-filter2">
        <div class="modal-news__top-left">
          <div class="encurso-container__select-container">
            <!-- Select Btn -->
            <div class="encurso-container__select-btn" @click="toggleSelect()">
              <div class="cat-selected" v-if="cat_selected == 1">Mensual</div>
              <div class="cat-selected" v-else-if="cat_selected == 2">Trimestral</div>
              <div class="cat-selected" v-else-if="cat_selected == 3">Semestral</div>
              <div class="cat-selected" v-else-if="cat_selected == 4">Anual</div>
              <div class="cat-selected" v-else-if="cat_selected == 5">Todas</div>
              <div class="cat-selected" v-else>Por período</div>
              <div class="modal-news__icon">
                <font-awesome-icon
                  :icon="open_select ? 'angle-up' : 'angle-down'"
                ></font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select">
              <div
                v-for="(status, k) in status_list"
                :key="k"
                class="cat-item"
                @click="filterStatus(status)"
              >
                {{ status }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-news__filter-container">
          <div class="encurso-container__title">Filtrar por período:</div>
          <div
            class="encurso-container__calendar-container"
            v-click-outside="handleCalendar"
          >
            <div class="calendar" style="position:absolute; top: 148px;" :class="calendar_side" v-if="show_calendar">
              <v-calendar
                color="blue"
                :min-date="min_date"
                :max-date="max_date"
                :attributes="attributes"
                @dayclick="onDayClick"
              />
              <div
                class="delete-date"
                @click="deleteDate()"
              >
                Eliminar
              </div>
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div
              class="encurso-container__start"
              @click="openCalendar('start-date')"
            >
              <span>{{ dateFormatymd(init_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div
              class="encurso-container__end unactive"
              v-if="init_date == 'Inicio'"
            >
              <span>{{ dateFormatymd(end_date) }}</span>

              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div
              class="encurso-container__end"
              v-if="init_date != 'Inicio'"
              @click="openCalendar('end-date')"
            >
              <span>{{ dateFormatymd(end_date) }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="">
        <p class="modal-news__subtitle mt-3 mb-0">Interacciones</p>
        <div class="modal-recognize__interactions">
          <div class="interacciones-container my-3">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ data[0].total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ data[0].title }}
              </p>
            </div>
            <img
              class="me-4 modal-news__modal-svg"
              :src="data[0].image"
              alt=""
            />
          </div>
          <div class="interacciones-container my-3">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ data[1].total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ data[1].title }}
              </p>
            </div>
            <img
              class="me-4 modal-news__modal-svg"
              :src="data[1].image"
              alt=""
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {

      data: [
        {
          image: require("@/assets/img/home-admin/3.svg"),
          total: "1.234",
          title: "Visitas",
        },
        {
          image: require("@/assets/img/home-admin/2.svg"),
          total: "1.234",
          title: "Me gusta",
        },
      ],
      // Filtro Estado
      cat_selected: "0",
      open_select: false,
      status_list: ["Mensual", "Trimestral", "Semestral", "Anual", "Todas"],

      // filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",

      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
    };

  },
  computed: {
    ...mapState("adminHomeModule", ["statisticsModule"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  async created(){
    await this.getStatisticsModule(this.params());
    console.log("statisticsModule", this.statisticsModule);
    this.fillNews();
  },
  methods: {
    ...mapActions("adminHomeModule", ["getStatisticsModule"]),
    // Parametros para llamada a statistics module
    params(){
      return {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: '23',
        idFiltro: this.cat_selected,
        fechaInicio: this.date_start,
        fechaFin: this.date_end,
      }
    },
    // LLena modal news con datos
    fillNews(){
      this.statisticsModule.estadisticas.forEach( e => {
        if(e.nombre == 'Visitas') this.data[0].total = e.total;
        if(e.nombre == 'Likes') this.data[1].total = e.total;
      })
    },
    // filtro estado
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },
    // filtro por estado
    async filterStatus(status){
      this.open_select = false;
      if(status == "Mensual") this.cat_selected = "1";
      if(status == "Trimestral") this.cat_selected = "2";
      if(status == "Semestral") this.cat_selected = "3";
      if(status == "Anual") this.cat_selected = "4";
      if(status == "Todas") this.cat_selected = "0";

      if(this.cat_selected!="3") await this.getStatisticsModule(this.params());
      if(this.cat_selected=="3" && this.date_start!='' && this.date_end!='') {
        await this.getStatisticsModule(this.params());
      }
      this.fillNews();
    },
    // filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },
    // eliminar una o ambas fechas
    async deleteDate(){
      if(this.calendar_side == "end-date"){
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
        if(this.cat_selected!="3") await this.getStatisticsModule(this.params());
        if(this.cat_selected=="3" && this.date_start!='' && this.date_end!='') {
          await this.getStatisticsModule(this.params());
        }
      } else {
        this.calendar_side = "",
        this.min_date = "";
        this.max_date = "";
        this.init_date = "Inicio";
        this.date_start = "";
        this.start_calendar = false,
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
        if(this.cat_selected!="3") await this.getStatisticsModule(this.params());
        if(this.cat_selected=="3" && this.date_start!='' && this.date_end!='') {
          await this.getStatisticsModule(this.params());
        }
      }
    },
    // abre calendario según posición (inicio o fin)
    openCalendar(side){
      this.show_calendar = true;
      this.calendar_side = side;
      if(this.calendar_side == "start-date") {
        this.min_date = "";
        if(this.end_calendar == true){
          this.max_date = this.date_end;
        }
      }
      if(this.calendar_side == "end-date") {
        this.max_date="";
        if(this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },
    // busca según las fechas asignadas
    async onDayClick(day) {
      if(this.calendar_side == "start-date"){
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      } 
      if(this.calendar_side == "end-date"){
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
      }
      this.show_calendar = false;
      if(this.cat_selected!="3") await this.getStatisticsModule(this.params());
      if(this.cat_selected=="3" && this.date_start!='' && this.date_end!='') {
        await this.getStatisticsModule(this.params());
      }
    },
  },
};
</script>
