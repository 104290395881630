<template>
  <div class="modal-news my-0 m-xxl-3">
    <h2 class="modal-news__title my-0">Noticias</h2>
    <div class="input-wrapper">
      <div class="modal-news__top-filter">
        <div class="modal-news__top-left">
          <v-select 
            class="modal-news__v-select"
            label="nombre"
            :options="filters" 
            v-model="filter" 
            @input="filterStatus()" 
            :clearable="false"
          ></v-select>

        </div>
        <div class="filter-container" v-show="id_filtro == 3">
          <div class="d-flex">
            <div
              class="encurso-container__calendar-container"
              v-click-outside="handleCalendar"
            >
              <div
                class="calendar modal-date__calendar-container"
                style="position: absolute; top: 50px"
                :class="calendar_side"
                v-if="show_calendar"
              >
                <v-calendar
                  color="blue"
                  :min-date="min_date"
                  :max-date="max_date"
                  :attributes="attributes"
                  @dayclick="onDayClick"
                />

                <div class="border-arrow" :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div
                class="encurso-container__start"
                @click="openCalendar('start-date')"
              >
                <span
                  style="color: #757679; font-family: 'Product Sans Regular'"
                  >{{ dateFormatymd(init_date) }}</span
                >

                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
                <div class="modal-date__icon-close">
                <font-awesome-icon 
                v-show="date_start != ''"
                icon="times" 
                @click.prevent.stop="deleteDate('start-date')"
                ></font-awesome-icon>
              </div>
              </div>
              
              <div
                class="encurso-container__end unactive"
                v-if="init_date == 'Inicio'"
              >
                <span
                  style="color: #757679; font-family: 'Product Sans Regular'"
                  >{{ dateFormatymd(end_date) }}</span
                >

                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div
                class="encurso-container__end"
                v-if="init_date != 'Inicio'"
                @click="openCalendar('end-date')"
              >
                <span>{{ dateFormatymd(end_date) }}</span>
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div class="modal-date__icon-close">
                <font-awesome-icon 
                  v-show="date_end != ''"
                  icon="times" 
                  @click.prevent.stop="deleteDate('end-date')"
                ></font-awesome-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-news__wrapper">
      <div class="">
        <p class="modal-news__subtitle my-2">Interacciones</p>
        <div class="modal-news__flex-container">
          <div class="interacciones-container" v-for="(d, i) in data" :key="i">
            <div class="ms-4">
              <p class="my-0 home-modal__interaction-text">
                {{ d.total }}
              </p>
              <p class="my-0 home-modal__interactions-subtext">
                {{ d.title }}
              </p>
            </div>
            <img class="modal-news__modal-svg me-4" :src="d.image" alt="" />
          </div>
        </div>
      </div>

      <div class="carrousel-modal">
        <p class="modal-news__subtitle ms-0 my-2">Top 5 más vistas</p>
        <div class="modal-news__card-noticias">
          <div class="carrousel-modal__card">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button v-show="topNews.length > 0"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button  v-show="topNews.length > 1"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button  v-show="topNews.length > 2"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button  v-show="topNews.length > 3"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button  v-show="topNews.length > 4"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
              </div>
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  v-for="(news, i) in topNews"
                  :key="i"
                  :class="{ active: i == 0 }"
                >
                  <div class="mt-2 carrousel-modal__card-carrousel">
                    <div class="carrousel-modal__fecha">
                      <p class="carrousel-modal__dia mb-0">
                        {{ getDay(news.fecha_noticia) }}
                      </p>
                      <p class="carrousel-modal__mes my-0">
                        {{ getMonth(news.fecha_noticia) }}
                      </p>
                    </div>
                    <img
                      :src="news.url_imagen_principal"
                      class="card-img-top"
                      alt=""
                    />
                    <div>
                      <p
                        class="carrousel-modal__card-title card-text mb-0 px-3"
                      >
                        {{ news.titulo_noticia }}
                      </p>
                      <div class="container">
                        <div class="carrousel-modal__indicators-container me-4">
                          <span
                            class="
                              d-flex
                              align-content-center align-items-center
                            "
                          >
                            <img
                              class="me-1"
                              src="@/assets/img/home-admin/comments.svg"
                              height="20"
                              alt=""
                            />{{ getData(news.comentarios) }}</span
                          >
                          <span class="d-flex align-content-center ms-3">
                            <img
                              class="me-1"
                              src="@/assets/img/home-admin/heart.svg"
                              alt=""
                            />{{ getData(news.likes) }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span aria-hidden="true"
                  ><img src="@/assets/img/home-admin/leftarrow.svg" alt=""
                /></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span aria-hidden="true"
                  ><img src="@/assets/img/home-admin/rigtharrow.svg" alt=""
                /></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {

  components: { Spinner },
  
  data() {
    return {
      show_spinner: false,
      data: [
        {
          image: require("@/assets/img/home-admin/3.svg"),
          total: "...",
          title: "Visitas",
        },
        {
          image: require("@/assets/img/home-admin/2.svg"),
          total: "...",
          title: "Me gusta",
        },
        {
          image: require("@/assets/img/home-admin/1.svg"),
          total: "...",
          title: "Comentarios",
        },
      ],
      // Filtro Estado
      filter: {
        id:"1",
        nombre:"Mensual"
      },
      id_filtro: '1',

      // filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
    };
  },

  computed: {
    ...mapState("adminHomeModule", ["statisticsModule", "topNews", "filters"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },

  async created() {
    this.show_spinner = true;
    let data = {
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
      idUsuario: this.$ls.get("user").id_usuario,
    };
    await this.getTopNews(data);
    await this.filterStatus();
    this.show_spinner = false;
  },

  methods: {
    ...mapActions("adminHomeModule", ["getStatisticsModule", "getTopNews"]),

    // filtro por estado
    async filterStatus() {
      this.show_spinner = true;
      console.log('Filtro', this.filter);
      this.id_filtro = this.filter.id;
      if( this.id_filtro == '1' || this.id_filtro == '2') {
        let data = {
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idModulo: "3",
          idFiltro: this.id_filtro,
          fechaInicio: this.getToday(),
          fechaFin: "",
        };
        await this.getStatisticsModule(data);
        this.fillNews();
        this.show_spinner = false;
      }else{
        this.show_spinner = false;
      }
    },
    // Obtiene mes actual y año actual (dia fijo)
    getToday(){
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let day = today.getDate();
      month = month.toString();
      if(month < 10 && month > 0) month = '0'+month;
      if(day < 10 && day > 0) day = '0'+day;
      console.log('ACTUAL DATE', year+'-'+month+'-'+day)
      return year+'-'+month+'-'+day;

    },

    // Busqueda por periodo
    async searchPerid(){
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: "3",
        idFiltro: this.filter.id,
        fechaInicio: this.date_start,
        fechaFin: this.date_end,
      };
      await this.getStatisticsModule(data);
      this.fillNews();
      this.show_spinner = false;
    },

    // LLena modal news con datos
    fillNews() {
      this.statisticsModule.estadisticas.forEach((e) => {
        if (e.nombre == "Visitas") this.data[0].total = e.total;
        if (e.nombre == "Likes") this.data[1].total = e.total;
        if (e.nombre == "Comentarios") this.data[2].total = e.total;
      });
    },

    // Fecha noticia
    getDay(day) {
      day = new Date(day);
      return day.getDate();
    },

    // Mes noticia
    getMonth(day) {
      day = new Date(day);
      let months = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
      return months[day.getMonth()];
    },

    // comentarios y likes
    getData(data) {
      if (data == undefined) return 0;
      if (data != undefined) return data.length;
    },

    // filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },

    // eliminar una o ambas fechas
    async deleteDate(side){
      this.show_calendar = false; 
      if(side == "end-date"){
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
      } else {
        this.calendar_side = "",
        this.init_date = "Inicio";
        this.date_start = "";
        this.start_calendar = false,
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
      }
    },

    // abre calendario según posición (inicio o fin)
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.date_end;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },

    // busca según las fechas asignadas
    async onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
        this.searchPerid();
      }
      this.show_calendar = false;
    },
  },
};
</script>
